import React, { lazy, Suspense } from "react";
import "./assets/css/app.css"
import PageLoader from "./loader/PageLoader";
const StartupScreen = lazy(() => import(`./StartupScreen`));

const App = () => {
  return (
    <Suspense fallback={<PageLoader />}>
      <StartupScreen />
    </Suspense>
  );
};

export default App;
